import {

    IMPLANT_LIST_REQUEST,
    IMPLANT_LIST_SUCCESS,
    IMPLANT_LIST_FAIL,

    IMPLANT_DETAILS_REQUEST,
    IMPLANT_DETAILS_SUCCESS,
    IMPLANT_DETAILS_FAIL,

    IMPLANT_DELETE_REQUEST,
    IMPLANT_DELETE_SUCCESS,
    IMPLANT_DELETE_FAIL,

    IMPLANT_CREATE_REQUEST,
    IMPLANT_CREATE_SUCCESS,
    IMPLANT_CREATE_FAIL,
    IMPLANT_CREATE_RESET,

    IMPLANT_UPDATE_REQUEST,
    IMPLANT_UPDATE_SUCCESS,
    IMPLANT_UPDATE_FAIL,
    IMPLANT_UPDATE_RESET,

    IMPLANT_COUNT_SUCCESS,
    IMPLANT_COUNT_REQUEST,
    IMPLANT_COUNT_FAIL,

    IMPLANT_DTO_FAIL,
    IMPLANT_DTO_REQUEST,
    IMPLANT_DTO_SUCCESS,
    IMPLANT_DTO_RESET,

    SET_CURRENT_PAGE,

} from "../constants/implantConstants";


export const implantListReducer = (
    state = {
        implants: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_LIST_REQUEST:
            return { loading: true, implants: [] };
        case IMPLANT_LIST_SUCCESS:
            // our state will have a list of implants after the request
            return {
                loading: false,
                implants: action.payload.implants,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantCountReducer = (state = { implantCounts: [] }, action) => {
  switch (action.type) {
    case IMPLANT_COUNT_REQUEST:
      return { loading: true, implantCounts: [] };
    case IMPLANT_COUNT_SUCCESS:
      return { loading: false, implantCounts: action.payload };
    case IMPLANT_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const implantDetailsReducer = (
    state = {
        implant: {}
    }, action) => {
    switch (action.type) {
        case IMPLANT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case IMPLANT_DETAILS_SUCCESS:
            // our state will have a list of implants after the request
            return { loading: false, implant: action.payload };
        case IMPLANT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantDetailsByBarcodeReducer = (
    state = {
        implant: { }
    }, action) => {
    switch (action.type) {
        case IMPLANT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case IMPLANT_DETAILS_SUCCESS:
            // our state will have a list of implants after the request
            return { loading: false, implant: action.payload };
        case IMPLANT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_DELETE_REQUEST:
            return { loading: true }

        case IMPLANT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case IMPLANT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const implantCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_CREATE_REQUEST:
            return { loading: true }

        case IMPLANT_CREATE_SUCCESS:
            return { loading: false, success: true, implant: action.payload }

        case IMPLANT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const implantCreateOrUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_CREATE_REQUEST:
            return { loading: true }

        case IMPLANT_CREATE_SUCCESS:
            return { loading: false, success: true, implant: action.payload }

        case IMPLANT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const implantDtoReducer = (state = { implantDto: {} }, action) => {
    switch (action.type) {
        case IMPLANT_DTO_REQUEST:
            return { loading: true, implantDto: {} }
        case IMPLANT_DTO_SUCCESS:
            return { loading: false, success: true, implantDto: action.payload }
        case IMPLANT_DTO_FAIL:
            return { loading: false, error: action.payload }
        case IMPLANT_DTO_RESET:
            return { implantDto: {} }
        default:
            return state
    }
}

export const implantUpdateReducer = (state = { implant: {} }, action) => {
    switch (action.type) {
        case IMPLANT_UPDATE_REQUEST:
            return { loading: true }

        case IMPLANT_UPDATE_SUCCESS:
            return { loading: false, success: true, implant: action.payload }

        case IMPLANT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_UPDATE_RESET:
            return { implant: {} }

        default:
            return state
    }
}