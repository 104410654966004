import React, { useState } from 'react';

const Item = ({ mode, children }) => {
    const statusColor = mode === 'success' ? 'green' : 'crimson'; // Updated color to crimson
    const symbol = mode === 'success' ? '✓' : '✕';

    return (
        <li style={{ color: statusColor }}>
            {symbol} {children}
        </li>
    );
};

const Checklist = ({ heading, children, expand = true }) => { // Set expand to true by default
    const [expanded, setExpanded] = useState(expand);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div itemID='checkList' style={{ border: '1px solid #005eb8', borderRadius: '5px', padding: '10px', backgroundColor: 'white', position: 'relative' }}>
            <a
                href="#checkList"
                onClick={toggleExpanded}
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    textDecoration: 'none',
                    color: 'white',
                    fontSize: '16px',
                    padding: '5px', // Add some padding
                }}
            >
                {expanded ? 'Collapse [-]' : 'Expand [+]'}
            </a>
            <div style={{ backgroundColor: '#005eb8', borderRadius: '5px 5px 0 0', padding: '10px', color: 'white', margin: '-10px -10px 10px -10px' }}>
                {heading}
            </div>
            {expanded && (
                <ul style={{ listStyleType: 'none', padding: '5px' }}>
                    {children}
                </ul>
            )}
        </div>
    );
};

Checklist.Item = Item;

export default Checklist;
