import {

    IMPLANT_CENTRE_LIST_REQUEST,
    IMPLANT_CENTRE_LIST_SUCCESS,
    IMPLANT_CENTRE_LIST_FAIL,

    IMPLANT_CENTRE_DETAILS_REQUEST,
    IMPLANT_CENTRE_DETAILS_SUCCESS,
    IMPLANT_CENTRE_DETAILS_FAIL,

    IMPLANT_CENTRE_DELETE_REQUEST,
    IMPLANT_CENTRE_DELETE_SUCCESS,
    IMPLANT_CENTRE_DELETE_FAIL,

    IMPLANT_CENTRE_CREATE_REQUEST,
    IMPLANT_CENTRE_CREATE_SUCCESS,
    IMPLANT_CENTRE_CREATE_FAIL,
    IMPLANT_CENTRE_CREATE_RESET,

    IMPLANT_CENTRE_UPDATE_REQUEST,
    IMPLANT_CENTRE_UPDATE_SUCCESS,
    IMPLANT_CENTRE_UPDATE_FAIL,
    IMPLANT_CENTRE_UPDATE_RESET,

    IMPLANT_CENTRE_COUNT_SUCCESS,
    IMPLANT_CENTRE_COUNT_REQUEST,
    IMPLANT_CENTRE_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/implantCentreConstants";

const initialState = {
    implantCentres: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const implantCentreListReducer = (
    state = {
        implantCentres: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_CENTRE_LIST_REQUEST:
            return { loading: true, implantCentres: [] };
        case IMPLANT_CENTRE_LIST_SUCCESS:
            // our state will have a list of implantCentres after the request
            return {
                loading: false,
                implantCentres: action.payload.implantCentres,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_CENTRE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantCentreCountReducer = (state = { implantCentreCounts: [] }, action) => {
  switch (action.type) {
    case IMPLANT_CENTRE_COUNT_REQUEST:
      return { loading: true, implantCentreCounts: [] };
    case IMPLANT_CENTRE_COUNT_SUCCESS:
      return { loading: false, implantCentreCounts: action.payload };
    case IMPLANT_CENTRE_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const implantCentreDetailsReducer = (
    state = {
        implantCentre: { comments: [] }
    }, action) => {
    switch (action.type) {
        case IMPLANT_CENTRE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case IMPLANT_CENTRE_DETAILS_SUCCESS:
            // our state will have a list of implantCentres after the request
            return { loading: false, implantCentre: action.payload };
        case IMPLANT_CENTRE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantCentreDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_CENTRE_DELETE_REQUEST:
            return { loading: true }

        case IMPLANT_CENTRE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case IMPLANT_CENTRE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const implantCentreCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_CENTRE_CREATE_REQUEST:
            return { loading: true }

        case IMPLANT_CENTRE_CREATE_SUCCESS:
            return { loading: false, success: true, implantCentre: action.payload }

        case IMPLANT_CENTRE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_CENTRE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const implantCentreUpdateReducer = (state = { implantCentre: {} }, action) => {
    switch (action.type) {
        case IMPLANT_CENTRE_UPDATE_REQUEST:
            return { loading: true }

        case IMPLANT_CENTRE_UPDATE_SUCCESS:
            return { loading: false, success: true, implantCentre: action.payload }

        case IMPLANT_CENTRE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_CENTRE_UPDATE_RESET:
            return { implantCentre: {} }

        default:
            return state
    }
}