import React, { useState, useEffect } from 'react';

const Callout = ({ mode, autoDismiss, dismissTime, header, message, actionHandler, actionLinkEnabled, actionLabel }) => {
    const [dismissed, setDismissed] = useState(false);

    useEffect(() => {
        if (autoDismiss) {
            const timeout = setTimeout(() => {
                setDismissed(true);
            }, dismissTime);
            return () => clearTimeout(timeout);
        }
    }, [autoDismiss, dismissTime]);

    const handleClose = () => {
        setDismissed(true);
    };

    if (dismissed) {
        return null;
    }

    return (
        <div
            style={{
                borderRadius: '5px',
                backgroundColor: mode === 'success' ? '#4CAF50' : mode === 'warning' ? '#FFC107' : mode === 'error' ? '#F44336' : '#2196F3',
                color: mode === 'warning' ? '#000' : mode === 'success' ? '#fff' : '#fff',
                padding: '10px',
                marginBottom: '10px',
                position: 'relative'
            }}
        >
            <button
                onClick={handleClose}
                type="button"
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: 'none',
                    border: 'none',
                    color: '#fff',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold'
                }}
            >
                &#x2715;
            </button>
            <div style={{ fontSize: '20px'}}>{header}</div>
            <div style={{ fontSize: '15px'}}>{message}</div>
            {actionLinkEnabled && <a href="#" onClick={actionHandler}>{actionLabel}</a>}
        </div>
    );
};

export default Callout;
