export const IMPLANT_CENTRE_LIST_REQUEST = 'IMPLANT_CENTRE_LIST_REQUEST'
export const IMPLANT_CENTRE_LIST_SUCCESS = 'IMPLANT_CENTRE_LIST_SUCCESS'
export const IMPLANT_CENTRE_LIST_FAIL = 'IMPLANT_CENTRE_LIST_FAIL'


export const IMPLANT_CENTRE_DETAILS_REQUEST = 'IMPLANT_CENTRE_DETAILS_REQUEST'
export const IMPLANT_CENTRE_DETAILS_SUCCESS = 'IMPLANT_CENTRE_DETAILS_SUCCESS'
export const IMPLANT_CENTRE_DETAILS_FAIL = 'IMPLANT_CENTRE_DETAILS_FAIL'


export const IMPLANT_CENTRE_DELETE_REQUEST = 'IMPLANT_CENTRE_DELETE_REQUEST'
export const IMPLANT_CENTRE_DELETE_SUCCESS = 'IMPLANT_CENTRE_DELETE_SUCCESS'
export const IMPLANT_CENTRE_DELETE_FAIL = 'IMPLANT_CENTRE_DELETE_FAIL'

export const IMPLANT_CENTRE_CREATE_REQUEST = 'IMPLANT_CENTRE_CREATE_REQUEST'
export const IMPLANT_CENTRE_CREATE_SUCCESS = 'IMPLANT_CENTRE_CREATE_SUCCESS'
export const IMPLANT_CENTRE_CREATE_FAIL = 'IMPLANT_CENTRE_CREATE_FAIL'
export const IMPLANT_CENTRE_CREATE_RESET = 'IMPLANT_CENTRE_CREATE_RESET'


export const IMPLANT_CENTRE_UPDATE_REQUEST = 'IMPLANT_CENTRE_UPDATE_REQUEST'
export const IMPLANT_CENTRE_UPDATE_SUCCESS = 'IMPLANT_CENTRE_UPDATE_SUCCESS'
export const IMPLANT_CENTRE_UPDATE_FAIL = 'IMPLANT_CENTRE_UPDATE_FAIL'
export const IMPLANT_CENTRE_UPDATE_RESET = 'IMPLANT_CENTRE_UPDATE_RESET'

export const IMPLANT_CENTRE_REQUEST = 'IMPLANT_CENTRE_REQUEST'
export const IMPLANT_CENTRE_SUCCESS = 'IMPLANT_CENTRE_SUCCESS'
export const IMPLANT_CENTRE_FAIL = 'IMPLANT_CENTRE_FAIL'
export const IMPLANT_CENTRE_RESET = 'IMPLANT_CENTRE_RESET'

export const IMPLANT_CENTRE_COUNT_REQUEST = "IMPLANT_CENTRE_COUNT_REQUEST";
export const IMPLANT_CENTRE_COUNT_SUCCESS = "IMPLANT_CENTRE_COUNT_SUCCESS";
export const IMPLANT_CENTRE_COUNT_FAIL = "IMPLANT_CENTRE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'